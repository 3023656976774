import React from "react"
import styled from "styled-components"

import Layout from "src/layout"
import { COLORS } from "src/constants"

const Title = styled.h1`
  color: ${COLORS.main};
`
const Content = styled.div`
  margin: 120px 60px;
  text-align: center;
  min-height: calc(100vh - 390px);
`

const NotFoundPage: React.FC = () => (
  <Layout title="404" noindex>
    <Content>
      <Title>Page introuvable</Title>
      <p>Désolé, cette page n’existe pas ou plus !</p>
    </Content>
  </Layout>
)

export default NotFoundPage
